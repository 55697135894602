import React from "react";
import Overview from '../images/overview.jpg'
import { Container, Row, Col } from "react-bootstrap";

const Admission = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2 align-items-center">
        <Col lg={7} className="px-5">
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "700",
              marginBottom: "30px",
            }}
          >
            An Overview
          </h1>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >Affinity Classes is the cumulative result of the passion of teaching and spread of learning. We believe that science subjects should not only be taught rather felt with detailed understanding of concept.</p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >The applications of science is absolutely necessary to excel in science, so we create an Innovative Assessment Test (IAT) system where students can give exams which consists of both conceptualized and application based problems which enables students to understand science and prepare for competitive exams like NEET, JEE ( Mains  & Advance ), CEE, NTSC, OLYMPIAD and BOARDS in a better way.</p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >Competitive exams are the bench mark to decide colleges and professions of students and we are leaving no stone unturned to provide them best faculties in the North East India. We have been guiding students from several years and Affinity Classes is the support system we created to make their way clear towards reaching their goals of cracking NEET, JEE ( Mains & Advance ) , CEE, NTSC, OLYMPIAD and BOARDS.</p>
        </Col>
        <Col lg={5}>
          <img src={Overview} alt="overview" style={{ borderRadius: '50px 10px 50px 10px' }} />
        </Col>
      </Row>
    </Container>
  );
};

export default Admission;
