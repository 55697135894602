import React from "react";
// Achievers
import { Container, Row, Col, Card } from "react-bootstrap";
const {AchieversYearWise2019, AchieversYearWise2020, AchieversYearWise2021 } = require('../utils/AchieversYearWise')

const Achievers = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Container className="mt-5">
        <h1
          style={{
            fontSize: "30px",
            fontWeight: "700",
            marginBottom: "30px",
          }}
        >
          Achievers
        </h1>

        <Row className="px-4 mt-2">
          <h5>Year 2021</h5>
          <hr/>
          <Col lg={12} className="px-3 mb-5">
            {AchieversYearWise2021.map((achievers) => (
                <Card key={achievers.id} className="p-3 border-0 shadow">
                  <Card.Img variant="top" src={achievers.image} />
                </Card>
              ))}
          </Col>
        </Row>

        <Row className="px-4 mt-2">
          <h5>Year 2020</h5>
          <hr/>
          <Col lg={12} className="px-3 mb-5">
            {AchieversYearWise2020.map((achievers) => (
                <Card key={achievers.id} className="p-3 border-0 shadow">
                  <Card.Img variant="top" src={achievers.image} />
                </Card>
              ))}
          </Col>
        </Row>

        <Row className="px-4 mt-2">
          <h5>Year 2019</h5>
          <hr/>
          <Col lg={12} className="px-3 mb-5">
              {AchieversYearWise2019.map((achievers) => (
                <Card key={achievers.id} className="p-3 border-0 shadow">
                  <Card.Img variant="top" src={achievers.image} />
                </Card>
              ))}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Achievers;
