import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import BarLoader from "react-spinners/BarLoader";
// Components
import Slider from "./components/Slider";
import Topbar from "./components/Topbar";
import Footer from "./components/Footer";
import Header from "./components/Header";
// Pages
import Home from "./pages/Home";
import Vision from "./pages/Vision";
import Mission from "./pages/Mission";
import FounderMessage from "./pages/FounderMessage";
import ManagingDirectorMessage from "./pages/ManagingDirectorMessage";
import FoundationCourse from "./pages/FoundationCourse";
import BridgeCourse from "./pages/BridgeCourse";
import SchoolIntegratedProgram from "./pages/SchoolIntegratedProgram";
import CrashCourse from "./pages/CrashCourse";
import DropperCourse from "./pages/DropperCourse";
import SummerCourse from "./pages/SummerCourse";
import DistanceLearningProgram from "./pages/DistanceLearningProgram";
import Admission from "./pages/Admission";
import AdmissionProcedure from "./pages/AdmissionProcedure";
import PaymentProcedure from "./pages/PaymentProcedure";
import InfrastructureGallery from "./pages/InfrastructureGallery";
import ScholarshipTest from "./pages/ScholarshipTest";
import InnovationTestProgram from "./pages/InnovationTestProgram";
import Library from "./pages/Library";
import Hostels from "./pages/Hostels";
import Laboratories from "./pages/Laboratories";
import Seminars from "./pages/Seminars";
import Sports from "./pages/Sports";
import CulturalEvents from "./pages/CulturalEvents";
import Cafeteria from "./pages/Cafeteria";
import Achievers from "./pages/Achievers";
import Centers from "./pages/Centers";
import LocateUs from "./pages/LocateUs";
import Career from "./pages/Career";
import AdmissionCell from "./pages/AdmissionCell";
import ApplyOnline from "./pages/ApplyOnline";
import Scholarship from "./pages/Scholarship";
import FeeStructure from "./pages/FeeStructure";
import NotableAchievers from "./pages/NotableAchievers";
import RegistrationForm from "./pages/RegistrationForm";
import PaymentMethod from "./pages/PaymentMethod";
import TestResult from "./pages/TestResult";
import Testimonials from "./pages/Testimonials";
import YoutubeLinks from "./pages/YoutubeLinks";
import MainTop from "./components/MainTop";

const Layout = () => {
  return (
    <>
      <MainTop/>
      <Topbar />
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: [<Slider />, <Home />],
      },
      {
        path: "/vision",
        element: [<Slider />,<Vision />],
      },
      {
        path: "/mission",
        element: [<Slider />,<Mission />],
      },
      {
        path: "/founder-message",
        element: [<Slider />,<FounderMessage />],
      },
      {
        path: "/managing-director-message",
        element: [<Slider />,<ManagingDirectorMessage />],
      },
      {
        path: "/foundation-course",
        element: [<Slider />,<FoundationCourse />],
      },
      {
        path: "/bridge-course",
        element: [<Slider />,<BridgeCourse />],
      },
      {
        path: "/school-integrated-program",
        element: [<Slider />,<SchoolIntegratedProgram/>],
      },
      {
        path: "/crash-course",
        element: [<Slider />,<CrashCourse/>],
      },
      {
        path: "/dropper-course",
        element: [<Slider />,<DropperCourse/>],
      },
      {
        path: "/summer-course",
        element: [<Slider />,<SummerCourse/>],
      },
      {
        path: "/distance-learning-program",
        element: [<Slider />,<DistanceLearningProgram/>],
      },
      {
        path: "/overview",
        element: [<Slider />,<Admission/>],
      },
      {
        path: "/admission-procedure",
        element: [<Slider />,<AdmissionProcedure/>],
      },
      {
        path: "/payment-procedure",
        element: [<Slider />,<PaymentProcedure/>],
      },
      {
        path: "/infrastructure-gallery",
        element: [<Slider />,<InfrastructureGallery/>],
      },
      {
        path: "/scholarship-text",
        element: [<Slider />,<ScholarshipTest/>],
      },
      {
        path: "/innovation-assestment-test",
        element: [<Slider />,<InnovationTestProgram/>],
      },
      {
        path: "/library",
        element: [<Slider />,<Library/>],
      },
      {
        path: "/hostels",
        element: [<Slider />,<Hostels/>],
      },
      {
        path: "/laboratories",
        element: [<Slider />,<Laboratories/>],
      },
      {
        path: "/seminars",
        element: [<Slider />,<Seminars/>],
      },
      {
        path: "/sports",
        element: [<Slider />,<Sports/>],
      },
      {
        path: "/cultural-events",
        element: [<Slider />,<CulturalEvents/>],
      },
      {
        path: "/cafeteria",
        element: [<Slider />,<Cafeteria/>],
      },
      {
        path: "/achievers",
        element: [<Slider />,<Achievers/>],
      },
      {
        path: "/centers",
        element: [<Slider />,<Centers/>],
      },
      {
        path: "/contact",
        element: [<Slider />,<LocateUs/>],
      },
      {
        path: "/career",
        element: [<Slider />,<Career/>],
      },
      {
        path: "/admission-cell",
        element: [<Slider />,<AdmissionCell/>],
      },
      {
        path: "/apply-online",
        element: [<Slider />,<ApplyOnline/>],
      },
      {
        path: "/scholarship",
        element: [<Slider />,<Scholarship/>],
      },
      {
        path: "/fee-structure",
        element: [<Slider />,<FeeStructure/>],
      },
      {
        path: "/notable-achievers",
        element: [<Slider />,<NotableAchievers/>],
      },
      {
        path: "/registration",
        element: [<Slider />,<RegistrationForm/>],
      },
      {
        path: "/payment-method",
        element: [<Slider />,<PaymentMethod/>],
      },
      {
        path: "/test-results",
        element: [<Slider />,<TestResult/>],
      },
      {
        path: "/testimonials",
        element: [<Slider />,<Testimonials/>],
      },
      {
        path: "/youtube-links",
        element: [<Slider />,<YoutubeLinks/>],
      },
      {
        path: "*",
        element: [<Slider />,<Home />],
      },
    ],
  },
]);

function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
      setTimeout(() => {
        setLoading(false);
      }, 1000);
  }, []);
  return (
    <Container fluid style={{ padding: "0" }}>
      {loading ? (
        <BarLoader color={'#36d7b7'} loading={loading} size={50} style={{ justifyContent:'center'}} />
      ) : (
        <RouterProvider router={router} />
      )}
    </Container>
  );
}

export default App;
