import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Cafeteria = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={12} className="px-5">
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "700",
              marginBottom: "30px",
            }}
          >
            Cafeteria
          </h1>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >Our small cafeteria provides snacks, coffee, tea, pastries, sandwiches, Maggie etc. Here students are seen enjoying music with light meals in a group. This place rejuvenate the students from the continuous class schedule in a day. Cafeteria is a self-paid and self service area.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Cafeteria;
