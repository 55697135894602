import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Hostels = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={12} className="px-5">
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "700",
              marginBottom: "30px",
            }}
          >
            Hostels
          </h1>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >We provide separate hostel facility for boys and girls. Every hostel has an accommodation based on single room, double sharing and triple sharing basis. Each and every hostel is managed and taken care by the individual hostel warden. Facilities provided :</p>
          <ul style={{marginTop:'20px', fontSize: '18px', marginLeft : '30px'}}>
            <li>Neat and clean rooms with toilets.</li>
            <li>Nutritious and Hygienic food.</li>
            <li>Centralized call booth to talk to parents.</li>
            <li>Disciplined daily schedule.</li>
            <li>24x7 Security services.</li>
            <li>Cell phone restricted campus.</li>
            <li>Under CCTV Surveillance</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Hostels;
