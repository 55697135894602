const AchieversYearWise2019 = [
    {
        id : 1,
        image : require("../images/rankers/achivers_2019.jpg"),
    },
];

const AchieversYearWise2020 = [
    {
        id : 1,
        image : require("../images/rankers/achivers_2020.jpg"),
    },
];

const AchieversYearWise2021 = [
    {
        id : 1,
        image : require("../images/rankers/achivers_2021.jpg"),
    },
];

module.exports = { AchieversYearWise2019, AchieversYearWise2020, AchieversYearWise2021 }