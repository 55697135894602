import React from "react";
// Achievers
import Img1 from "../images/centers/center1.jpg";
import { Container, Row, Col, Card } from "react-bootstrap";

const Centers = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Container className="mt-5">
        <h1
          style={{
            fontSize: "30px",
            fontWeight: "700",
            marginBottom: "30px",
          }}
        >
          Our Centers
        </h1>
        <Card className="p-3 mb-5">
          <Row className="text-center">
            <p><b>Head Office : </b>2nd Floor, TD Complex, Near Big Bazaar, Rajgarh road, Guwahati-07, Assam</p>
          </Row>
          <Row>
            <Col lg={6} sm={6} md={4}>
              <Card.Img variant="top" src={Img1} />
            </Col>
            <Col lg={6} sm={6} md={4}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14323.617534183468!2d91.7694571!3d26.1672433!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x99777dd35aa7652!2sAffinity%20Classes!5e0!3m2!1sen!2sin!4v1673606801565!5m2!1sen!2sin"
                width="100%" height="100%"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
          </Row>
        </Card>
      </Container>
    </Container>
  );
};

export default Centers;
