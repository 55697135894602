import { Link } from "react-router-dom";
import FooterLogo from "../images/logo2.png";


const Footer = () => {
  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-content">
                <div className="footer-head">
                  <div className="footer-logo">
                    <img src={FooterLogo} alt="" style={{ width: "100%" }} />
                  </div>

                  <p className="text-light" style={{textAlign:'justify'}}>
                    <b>Affinity Classes</b> facilitates the students to uncover their prospects at sports and other activities. The mentors of the academy are always available to clear the doubts of the students, even beyond the classroom. Affinity Classes also holds motivational seminars from time to time... <Link to="/overview" style={{ color : 'teal'}}>read more</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="footer-content">
                <div className="footer-head">
                  <h4 className="text-light fw-bold">Conatct us</h4>
                  <hr className="text-light"/>
                  <p className="text-light">
                    <b>Corporate Office</b><br/>
                    M-17, 1st Floor, Old DLF Colony, Sector-14, Gurugram, Delhi-NCR
                  </p>

                  <p className="text-light">
                    <b>Head Office</b><br/>
                    2nd Floor, TD Complex, Near Big Bazaar, Rajgarh road,
                    Guwahati-07, Assam
                  </p>

                  <p className="text-light">
                    <b>Branch Office</b><br/>
                    <ol>
                    <li><span>Tarun Nagar, ABC, GS Road, Guwahati-05, Assam.</span></li>
                    <li><span>Prafulla Boro Path, Near L.P. School, Rupnagar, Guwahati-32, Assam.</span></li>
                    <li></li>
                    </ol>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="footer-content">
                <div className="footer-head">
                  <h4 className="text-light fw-bold">Links</h4>
                  <hr className="text-light"/>
                  <div className="quick-links">
                    <ul>
                      <li>
                        <Link to="/" style={{}}>
                          Home
                        </Link>
                      </li>

                      <li>
                        <Link to="/" style={{}}>
                          About Affinity
                        </Link>
                      </li>

                      <li>
                        <Link to="/" style={{}}>
                          Admission Procedure
                        </Link>
                      </li>

                      <li>
                        <Link to="/" style={{}}>
                          Apply Online
                        </Link>
                      </li>

                      <li>
                        <Link to="/" style={{}}>
                          Scholarship
                        </Link>
                      </li>

                      <li>
                        <Link to="/privacy-policy" style={{}}>
                          Payment Procedure
                        </Link>
                      </li>

                      <li>
                        <Link to="/privacy-policy" style={{}}>
                          Career
                        </Link>
                      </li>

                      <li>
                        <Link to="/privacy-policy" style={{}}>
                          Contact us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-area-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="copyright text-center">
                <p style={{ color: "#fff", fontSize : '12px' }}>
                  &copy; Copyright &amp; Powered by <strong>AFFINITY GROUP OF INSTITUTIONS</strong>. All Rights
                  Reserved. Designed by{" "}
                <a
                  href="https://otechnix.com/" rel="noopener noreferrer" target="_blank"
                  className="text-decoration-none text-info"
                >
                  OTECHNONIX INDIA PVT LTD.
                </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;