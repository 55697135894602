import React from "react";
import AFFSAT from '../images/notice/Test-AFFSAT.jpeg'
import { Container, Row, Col } from "react-bootstrap";

const ScholarshipTest = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={8} className="px-5">
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "700",
              marginBottom: "30px",
            }}
          >
            Affinity Scholarship cum Admission Test ( AFF – SAT )
          </h1>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >Any student at one time is a great performer and the other time his / her performance is not what he / she was. So Affinity Classes has provided a platform to all by launching AFF SAT Scholarship cum Admission Test. The test comprises of two sets, one for the class Xth students and the other for the class XIIth.</p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >AFF SAT is totally an objective based test for a period of 40 minutes. Who so appears for the test gets the discount in the tuition fee for ones effort. Boy candidate get 20% where as girl candidate get 40% on the tuition fee. Affinity Classes is also promoting the campaign of BETI PADAHAO BETI BACHAO.</p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >There are different slabs of scholarship available for the student, but the scholarship is provided on any one slot which is on the higher side. </p>
        </Col>

        <Col lg={4}>
          <img src={AFFSAT} alt="" />
        </Col>
      </Row>
    </Container>
  );
};

export default ScholarshipTest;
