import React from "react";
import CoursesNav from "../components/CoursesNav";
import CourseImg from "../images/course.jpeg"
import { Container, Row, Col } from "react-bootstrap";

const BridgeCourse = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={9} className="px-5 mb-3">
          <Row>
            <Col lg={6}>
              <h1
                style={{
                  fontSize: "30px",
                  fontWeight: "700",
                  marginBottom: "30px",
                }}
              >
                Bridge Course
              </h1>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "18px",
                  lineHeight: "30px",
                  textIndent: "30px",
                  marginTop: "20px",
                }}
              >
                This course is for the student who have cleared their Class Xth and moving to Class XI. As the name suggest the above course plays a role of the bridge for the student to step into Class XI opting as Science as the main stream. Students who wants to build up a strong base for the XI – XII, it helps in building the foundation.
              </p>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "18px",
                  lineHeight: "30px",
                  textIndent: "30px",
                  marginTop: "20px",
                }}
              >The course is of a small duration but make the students clear with all concepts in theoretical as well as practical for the subject of Physics, Chemistry, Biology and Mathematics.</p>
            </Col>
            <Col lg={6}>
              <img src={CourseImg} alt="" />
            </Col>
          </Row>
        </Col>
        <CoursesNav />
      </Row>
    </Container>
  );
};

export default BridgeCourse;
