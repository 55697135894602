import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScholarshipPDF from '../images/scholarship.pdf'

const Scholarship = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="justify-content-center">
        <Col lg={12} className="p-5">
          <iframe src={ScholarshipPDF} frameborder="0" style={{ width : '100%', height : '1200px'}}></iframe>
        </Col>
      </Row>
    </Container>
  );
};

export default Scholarship;
