const marqueeItems = [
    {
      id : 1,
      title : "Remember The Dates : Latest news AH",
      content : "",
      date : "27 Jan 2023 18:00:15 PM",
      link : require("../images/notice/Latest news AH.jpg"),
      image : require("../images/notice/Latest news AH.jpg"),
    },
    {
      id : 2,
      title : "Remember The Dates : Letest news CB",
      content : "",
      date : "27 Jan 2023 12:00:15 PM",
      link : require("../images/notice/Letest news cb.jpg"),
      image : require("../images/notice/Letest news cb.jpg"),
    },
    {
      id : 3,
      title : "School Integrated Program for class XI & XII (CBSE & AHSEC)",
      content : "",
      date : "27 Jan 2023 12:00:15 PM",
      link :  require("../images/notice/Course-Sip.jpg"),
      image : require("../images/notice/Course-Sip.jpg"),
    },
    {
      id : 4,
      title : "Test AFFSAT",
      content : "",
      date : "27 Jan 2023 12:00:15 PM",
      link : require("../images/notice/Test-AFFSAT.jpeg"),
      image : require("../images/notice/Test-AFFSAT.jpeg"),
    },
    {
      id : 5,
      title : "Test IAT",
      content : "",
      date : "27 Jan 2023 12:00:15 PM",
      link : require("../images/notice/test-IAT.jpg"),
      image : require("../images/notice/test-IAT.jpg"),
    },
    {
      id : 6,
      title : "Admission open NEET/JEE 2023 with AFFINITY CLASSES",
      content : "",
      date : "27 Jan 2023 12:00:15 PM",
      link : "/apply-online",
      image : require("../images/notice/admission.jpeg"),
    },
    {
      id : 7,
      title : "CRASH COURSE || Admission open || NEET/JEE 2023",
      content : "",
      date : "27 Jan 2023 12:00:15 PM",
      link : "/crash-course",
      image : require("../images/notice/crash_course.jpeg"),
    },
]

export default marqueeItems;