import React from "react";
import CoursesNav from "../components/CoursesNav";
import CourseImg from "../images/course.jpeg"
import { Container, Row, Col } from "react-bootstrap";

const DistanceLearningProgram = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={9} className="px-5 mb-3">
          <Row>
            <Col lg={6}>
              <h1
                style={{
                  fontSize: "30px",
                  fontWeight: "700",
                  marginBottom: "30px",
                }}
              >
                Distance Learning Program
              </h1>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "18px",
                  lineHeight: "30px",
                  textIndent: "30px",
                  marginTop: "20px",
                }}
              >
                Distance learning is the process to create and provide access to learning when the course curriculum and the students are separated by time and distance or both. The pandemic period has made the students more proficient and tech-savvy. Same education system has reached to its best use of technology methods to reach to the students.
              </p>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "18px",
                  lineHeight: "30px",
                  textIndent: "30px",
                  marginTop: "20px",
                }}
              >Distance learning program has also come as an outcome of skill development in students where as for educators also.</p>
            </Col>
            <Col lg={6}>
              <img src={CourseImg} alt="" />
            </Col>
          </Row>
        </Col>
        <CoursesNav />
      </Row>
    </Container>
  );
};

export default DistanceLearningProgram;
