import React from 'react'
import './MainTop.css'

const MainTop = () => {
  return (
    <div className="main-top">
        <div className="container fluid">
            <div className="row">
                <div className="col-lg-6">
                    <a href="https://www.facebook.com/affinityclasses/" target="_blank" title="Facebook" className="social-icon facebook-icon me-1"><i className="fa fa-facebook"></i></a>
                    <a href="https://www.instagram.com/affinityclasses/?hl=en" target="_blank" title="Instagram" className="social-icon instagram-icon me-1"><i className="fa fa-instagram"></i></a>
                    <a href="https://www.youtube.com/@AFFINITYDIGITAL" target="_blank" title="Youtube" className="social-icon youtube-icon me-1"><i className="fa fa-youtube"></i></a>
                </div>
                <div className="col-lg-6">
                    <div style={{textAlign: 'right'}}>
                        <a href="tel:+916002654752" target="_blank" className="text-decoration-none me-4" style={{ fontSize : '14px', fontWeight : 'bolder', color:'#fff'}}>&nbsp;<i className="fa fa-phone"></i> 7002131659</a>
                        <a href="tel:+916002654752" target="_blank" className="text-decoration-none" style={{ fontSize : '14px', fontWeight : 'bolder',  color:'#fff'}}><i className="fa fa-phone"></i> 6002654752</a> 
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MainTop