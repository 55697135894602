import React from "react";
import Director from "../images/director.jpg";
import { Container, Row, Col } from "react-bootstrap";

const ManagingDirectorMessage = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="p-5 align-items-center">
        <Col lg={5}>
          <div className="text-center">
            <img src={Director} alt="" className="w-75 h-75" style={{ border:'5px solid #ddd', padding :'20px', borderRadius : '50px 10px 50px 10px'}} />
            {/* <h4 className="mt-2"><b>Mr. Ankit Agarwal</b></h4>
            <p>FOUNDER &amp; CEO <i>of</i> AFFINITY</p> */}
          </div>
        </Col>
        <Col lg={7}>
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "700",
              marginBottom: "30px",
            }}
          >
            Managing Director Message{" "}
          </h1>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px"
            }}
          >
            A “Managing Director” of any company automatically makes any person to sit on a hot seat having so much responsibilities and must have that much of skill to handle those responsibilities. It was my great pleasure and opportunity by the Grace of God that I come in touch of my own Guru Ankit Sir. He had the touch of turning a stone into gold and that what he did to me by his guidance, work pressure and an derived aim to go on the path of success.
          </p>

          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px"
            }}
          >
            Presently, I can only say to all my students, faculty members, management staff, parents and my co-workers, that I am for you and my every single minute of life is devoted toward you all.
          </p>

          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px"
            }}
          >
            I assure you all to be of service to you with whole heartedly and the promises made by me to any will always be full filled in what so ever case. The vision and mission of Affinity Classes and more of it dream goals set by our CEO Ankit sir will not let down in any way.
          </p>

          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px"
            }}
          >
            My request to all my co-faculty staff, students and parents is let us we all together create so good students in terms of their education, moral values and over all the serve to Nation, a great success.
          </p>

          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px"
            }}
          >
            Thanks and regards for all your support.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ManagingDirectorMessage;
