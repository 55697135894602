import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import allTestimonials from '../utils/alltestimonials'
import Card from 'react-bootstrap/Card';

const Testimonials = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Container className="mb-5">
        <Row>
          <Col lg={12} className="p-5">
            <h2 className="text-center"><b>What Students Think of AFFINITY</b></h2>
          </Col>
          {allTestimonials.map((testimonials) => (
            <Col lg={4} md={6} className="mb-3" key={testimonials.id}>
              <Card className="shadow-sm" style={{ height : '430px' }}>
                <Card.Header>
                  <div className="text-center p-1">
                    <img src={testimonials.image} alt="" style={{ height: '100px', borderRadius: '30px 5px 30px 5px' }} />
                    <Card.Title className="mt-2">{testimonials.name}</Card.Title>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Card.Text className="text-center">
                    {testimonials.content}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default Testimonials;
