const TopperSpeech2022 = [
    {
        id : 1,
        video : "https://www.youtube.com/embed/-vCduAusIlM"
    },
    {
        id : 2,
        video : "https://www.youtube.com/embed/lj86HRCy1HM"
    },
    {
        id : 3,
        video : "https://www.youtube.com/embed/HujEOh7oV4Q"
    }
]

const StrategyOfNEET = [
    {
        id : 1,
        video : "https://www.youtube.com/embed/uqngyGqV3cU"
    },
    {
        id : 2,
        video : "https://www.youtube.com/embed/gZ1TMD38pX4"
    }
]

module.exports = {TopperSpeech2022, StrategyOfNEET} 