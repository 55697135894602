const AllAchievers = [
    {
        id : 1,
        image : require("../images/rankers/0001.jpg"),
    },
    {
        id : 2,
        image : require("../images/rankers/0002.jpg"),
    },
    {
        id : 3,
        image : require("../images/rankers/0003.jpg"),
    },
    {
        id : 4,
        image : require("../images/rankers/0004.jpg"),
    },
    {
        id : 5,
        image : require("../images/rankers/0005.jpg"),
    },
    {
        id : 6,
        image : require("../images/rankers/0006.jpg"),
    },
    {
        id : 7,
        image : require("../images/rankers/0007.jpg"),
    },
    {
        id : 8,
        image : require("../images/rankers/0008.jpg"),
    },
    {
        id : 9,
        image : require("../images/rankers/0009.jpg"),
    },
    {
        id : 10,
        image : require("../images/rankers/0010.jpg"),
    },
    {
        id : 11,
        image : require("../images/rankers/0011.jpg"),
    },
    {
        id : 12,
        image : require("../images/rankers/0012.jpg"),
    },
    {
        id : 13,
        image : require("../images/rankers/0013.jpg"),
    },
    {
        id : 14,
        image : require("../images/rankers/0014.jpg"),
    },
    {
        id : 15,
        image : require("../images/rankers/0015.jpg"),
    },
    {
        id : 16,
        image : require("../images/rankers/0016.jpg"),
    },
    {
        id : 17,
        image : require("../images/rankers/0017.jpg"),
    },
    {
        id : 18,
        image : require("../images/rankers/0018.jpg"),
    },
    {
        id : 19,
        image : require("../images/rankers/0019.jpg"),
    },
    {
        id : 20,
        image : require("../images/rankers/0020.jpg"),
    }
]

export default AllAchievers