import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Seminars = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={12} className="px-5">
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "700",
              marginBottom: "30px",
            }}
          >
            Seminars
          </h1>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >Seminars are conducted for the enrolled student on an alternate week. These sessions give the opportunity to discuss topics in depth among small groups because any lecture is usually with a large group of students. These seminars engage students in the discussion of an academic subject with an aim to gain insight into the subject.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Seminars;
