import React from "react";
import CoursesNav from "../components/CoursesNav";
import CourseImg from "../images/course/School_Integrated_Program.jpg"
import { Container, Row, Col } from "react-bootstrap";

const SchoolIntegratedProgram = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={9} className="px-5 mb-3">
          <Row>
            <Col lg={6}>
              <h1
                style={{
                  fontSize: "30px",
                  fontWeight: "700",
                  marginBottom: "30px",
                }}
              >
                School Integrated Program
              </h1>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "18px",
                  lineHeight: "30px",
                  textIndent: "30px",
                  marginTop: "20px",
                }}
              >
                It is one of the best program for the medical and engineering aspirants having a goal of cracking the NEET / IIT – JEE in a single attempt. It is the combination of Schooling and coaching under one roof for the students of Class XI and XII as an integrated.  School Integrated Program is to make the student well prepared for the board examination along with the concepts of getting prepared for the competitive examination .
              </p>
              <h5><b>Characteristics of the program :</b></h5>
              <ul style={{ marginTop: '20px', fontSize: '18px', marginLeft: '30px' }}>
                <li>Problem Solving is the primary focus.</li>
                <li>It’s a compilation of curriculum and its assessment.</li>
                <li>Provides creativity, adoptability and critical reasoning. </li>
                <li>Variety of learning styles, theories and multiple intelligences.</li>
              </ul>
              <h5><b>Benefits :</b></h5>
              <ul style={{ marginTop: '20px', fontSize: '18px', marginLeft: '30px' }}>
                <li>Provides better understanding of the content.</li>
                <li>Develops higher level thinking skills.</li>
                <li>Basics and advance concepts with different ways of solving.</li>
                <li>Regular assessment enhances performance.</li>
                <li>Increases performance within students due to combined study.</li>
              </ul>
            </Col>
            <Col lg={6}>
              <img src={CourseImg} alt="" />
            </Col>
          </Row>
        </Col>
        <CoursesNav />
      </Row>
    </Container>
  );
};

export default SchoolIntegratedProgram;
