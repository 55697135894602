import React from "react";
import CoursesNav from "../components/CoursesNav";
import CourseImg from "../images/course.jpeg"
import { Container, Row, Col } from "react-bootstrap";

const SummerCourse = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={9} className="px-5 mb-3">
          <Row>
            <Col lg={6}>
              <h1
                style={{
                  fontSize: "30px",
                  fontWeight: "700",
                  marginBottom: "30px",
                }}
              >
                Summer Course
              </h1>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "18px",
                  lineHeight: "30px",
                  textIndent: "30px",
                  marginTop: "20px",
                }}
              >
                Most liked course by the students who are appearing for their CBSE board examination. This course was launched on the demand of the students as well as by their parents of JNV and KV schools. Complete utilization of summer holidays by the students to revise the whole course and prepare for the boards.
              </p>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "18px",
                  lineHeight: "30px",
                  textIndent: "30px",
                  marginTop: "20px",
                }}
              >Best use of holidays in summer to make your career more brighten and achievable.</p>
            </Col>
            <Col lg={6}>
              <img src={CourseImg} alt="" />
            </Col>
          </Row>
        </Col>
        <CoursesNav />
      </Row>
    </Container>
  );
};

export default SummerCourse;
