import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const CulturalEvents = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={12} className="px-5">
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "700",
              marginBottom: "30px",
            }}
          >
            Cultural Events
          </h1>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >Affinity cultural events are one of the mesmerizing events. Each and every festival or any occasion is celebrated with full enthusiasm and maximum participation. The cultural events are a collaborative participation by all staff, faculty and students, sometimes by the parents also.</p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >The different cultural events removes stage fear, generates self-confidence and on all improves academic performance in a student.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default CulturalEvents;
