import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Sports = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={12} className="px-5">
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "700",
              marginBottom: "30px",
            }}
          >
            Sports
          </h1>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >Physical activity is also required along with the mental activity for the all-round development of a student in his / her growth. We have a separate cell to provide and engage students for different sports activity based on the choice of the student. A student can opt for any games like Cricket, Football, Volleyball, Table Tennis, Badminton, Chess, Carom etc.</p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >Sports cell focuses on both indoor games as well as outdoor games simultaneously for a good physical as well as mental growth of a student and also develops a healthy competitive attitude.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Sports;
