import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const CoursesNav = () => {
  return (
    <Col lg={3}>
      <div className="card border-0">
        <div className="card-header">
          <h5 className="p-3"><b>All Courses</b></h5>
        </div>
        <div className="card-body">
          <ul className="course-list" style={{ paddingLeft: "0px" }}>
            <li>
              <i class="fa fa-angle-right"></i>{" "}
              <Link className="course-links" to="/foundation-course">
                Foundation Course
              </Link>
            </li>
            <li>
              <i class="fa fa-angle-right"></i>{" "}
              <Link className="course-links" to="/bridge-course">
                Bridge Course
              </Link>
            </li>
            <li>
              <i class="fa fa-angle-right"></i>{" "}
              <Link className="course-links" to="/school-integrated-program">
                School Integrated Program
              </Link>
            </li>
            <li>
              <i class="fa fa-angle-right"></i>{" "}
              <Link className="course-links" to="/crash-course">
                Crash Course
              </Link>
            </li>
            <li>
              <i class="fa fa-angle-right"></i>{" "}
              <Link className="course-links" to="/dropper-course">
                Dropper Course
              </Link>
            </li>
            <li>
              <i class="fa fa-angle-right"></i>{" "}
              <Link className="course-links" to="/summer-course">
                Summer Course
              </Link>
            </li>
            <li>
              <i class="fa fa-angle-right"></i>{" "}
              <Link className="course-links" to="/distance-learning-program">
                Distance Learning Course
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Col>
  );
};

export default CoursesNav;
