import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const ApplyOnline = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row>
        <Col lg={12} className="p-5">
          <div className="text-center">
            <iframe src="https://forms.gle/RFyq2VZ6Uc9U6sQC7" frameborder="0" style={{ width : '100%', height : '3000px'}}></iframe>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ApplyOnline;
