import { Link } from 'react-router-dom';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Container, Row, Col } from "react-bootstrap";
import marqueeItems from '../utils/notice'
import AllAchievers from '../utils/achievers';
import importantLinks from '../utils/importantLinks'

const Home = () => {
  return (
    <>
      <Col lg={12} className="text-center mb-4" style={{ margin: '0px', backgroundColor: 'teal' }}>
        <marquee><h4 style={{ fontWeight: '700', color: "#fff", marginTop: '10px' }}><span className='text-warning'>85 Students</span> Scored 550+ Marks in <span className='text-warning'>NEET 2022</span></h4></marquee>
      </Col>

      <Container fluid className="mb-5">
        <Row>
          <Col lg={7}>
            <OwlCarousel
              items={3}
              className="owl-theme"
              loop
              autoplay={true}
              dots={true}
              nav={false}
            >
              {AllAchievers.map((achievers) => (
                <Col key={achievers.id}>
                  <img className="img" alt="" src={achievers.image} />
                </Col>
              ))}
            </OwlCarousel>
            <Col lg={12} className="p-4">
              <h2 style={{ fontWeight: '700', color: 'orange' }}>Innovation Powers</h2>
              <h4>The <span style={{ fontSize: '40px', color: '#660066' }}><b>AFFINITY CLASSES</b></span><br /><small><i>In Creating Next Generation</i></small></h4>
              <p style={{ textAlign: 'justify', fontSize: '18px', marginTop: '20px' }}>
                Affinity Classes, needless to say has made it <b>EASY FOR NORTH-EAST STUDENTS</b> thereby, has consistently produced toppers and also maximum number of selections in NEET and JEE.
              </p>
              <p style={{ textAlign: 'justify', fontSize: '18px' }}><b>AFFINITY CLASSES</b> – a name synonymous for success in <b>JEE</b>, <b>NEET</b> and <b>CEE</b> exams, is more than a success partner of engineering graduates for written examinations and personality tests, which are integral to the selection process. <b>AFFINITY CLASSES</b> has maintained its exclusivity by consistently producing toppers and highest number of good rank holders in <b>JEE</b>, <b>NEET</b> and <b>CEE</b> right since its inception in the year 2017-2018.</p>
            </Col>
          </Col>
          <Col lg={5}>
            <div className="col-lg-12" style={{ border: "4px solid #660066" }}>
              <div className="bg-warning text-center py-2" style={{ height: '40px' }}>
                <h5 className="text-dark"><b>LATEST UPDATES &amp; NEWS @affinity</b></h5>
              </div>
              <div className="p-2" style={{ height: "800px" }}>
                <marquee direction="up" scrollamount="2" style={{ height: '780px' }}>
                  {marqueeItems.map((notice) => (
                    <div className="row align-items-center px-2" key={notice.id}>
                      <div className="col-4">
                        <Link to={notice.link}   target="_blank"><img src={notice.image} alt="" style={{ width: '100%' }} /></Link>
                      </div>
                      <div className="col-6">
                        <div className="text-left">
                          <h6 style={{ fontSize: '13px' }}><b><Link to={notice.link} target="_blank">{notice.title}</Link></b></h6>
                          <p style={{ fontSize: '11px' }}>{notice.content}</p>
                          <p style={{ fontSize: '8px' }}>Published on : {notice.date}</p>
                        </div>
                      </div>
                      <div className="col-2"><Link to={notice.link}  target="_blank" style={{ fontSize: '10px', color: 'red' }}>Read more</Link></div>
                      <hr style={{ marginTop : '10px'}} />
                    </div>
                  ))}
                </marquee>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Col className='mt-5 p-5' style={{ backgroundColor: '#eee' }}>
        <div className="text-center">
          <h3 class="text-dark"><b>QUICK LINKS</b></h3>
        </div>
        <Row className="mt-3 p-3">
          {importantLinks.map((links) => (
            <Col xs={6} sm={6} md={4} lg={3} className="mb-3 p-3" key={links.id}>
              <Col
                style={{ border: "3px solid #660066", padding: "10px 0px" }}
                className="boxes"
              >
                <Link to={links.link}>
                  <img src={links.image} alt="download" />
                </Link>
              </Col>
            </Col>
          ))}
        </Row>
      </Col>

    </>
  );
};

export default Home;
