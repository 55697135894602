import React from "react";
import CoursesNav from "../components/CoursesNav";
import CourseImg from "../images/course.jpeg"
import { Container, Row, Col } from "react-bootstrap";

const DropperCourse = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={9} className="px-5 mb-3">
          <Row>
            <Col lg={6}>
              <h1
                style={{
                  fontSize: "30px",
                  fontWeight: "700",
                  marginBottom: "30px",
                }}
              >
                Dropper Course
              </h1>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "18px",
                  lineHeight: "30px",
                  textIndent: "30px",
                  marginTop: "20px",
                }}
              >
                Dropper course is for the students who have decided to give a
                complete year for their preparation of NEET / IIT – JEE. In this
                particular course the student studies the basics to advance course
                of Class XI and XII both for the subjects Physics, Biology,
                Chemistry and Mathematics. Very effective and crucial course for the
                student taking a year drop for his competitive examination
                preparation.
              </p>

              <ul
                style={{ marginTop: "20px", fontSize: "18px", marginLeft: "30px" }}
              >
                <li>
                  Droppers get more time as comparative to 12thy as they don’t have
                  board issue.{" "}
                </li>
                <li>
                  Basics are clear to the droppers they only need practice and
                  practice.
                </li>
                <li>
                  Student is more dedicated and sincere as he /she has already faced
                  failure.{" "}
                </li>
                <li>
                  Dropper know the weakness and strength so they can mange time
                  accordingly easily.{" "}
                </li>
              </ul>
            </Col>
            <Col lg={6}>
              <img src={CourseImg} alt="" />
            </Col>
          </Row>
        </Col>
        <CoursesNav />
      </Row>
    </Container>
  );
};

export default DropperCourse;
