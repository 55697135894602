import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Iframe from 'react-iframe'
const {TopperSpeech2022, StrategyOfNEET} = require('../utils/allyoutube');

const YoutubeLinks = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Container className="mt-5 mb-5">
        <h4 className="mb-3"><b>TOPPER SPEECH 2022</b></h4>
        <Row>
          {TopperSpeech2022.map((video)=>(
          <Col lg={4} className="mb-3" key={video.id}>
            <Iframe url={video.video}
              width="100%"
              height="200px"
              className=""
              display="block"
              position="relative" />
          </Col>
          ))}
        </Row>

        <h4 className="mb-3 mt-4"><b>20 Days Strategy for NEET</b></h4>
        <Row>
          {StrategyOfNEET.map((video)=>(
          <Col lg={4} className="mb-3" key={video.id}>
            <Iframe url={video.video}
              width="100%"
              height="200px"
              className=""
              display="block"
              position="relative" />
          </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default YoutubeLinks;
