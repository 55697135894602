import React from "react";
import IAT from '../images/notice/test-IAT.jpg'
import { Container, Row, Col } from "react-bootstrap";

const InnovationTestProgram = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={8} className="px-5">
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "700",
              marginBottom: "30px",
            }}
          >
            Innovative Test Program (IAT)
          </h1>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >It is always to assess oneself after the completion of any learning task. When it comes to preparation for any competitive examination, it is our moral duty to find out what the student has gained and what the mentor has delivered.</p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >This IAT was started with the same concept. It comprises of series of test depending upon the curriculum for the course opted by the student. From the day we started this test series we now proudly state that it is the “Most preferable test series by toppers”. We also proudly say that this test series covers 80% questions expected in NEET / JEE.</p>
        </Col>
        <Col lg={4}>
        <img src={IAT} alt="" />
        </Col>
      </Row>
    </Container>
  );
};

export default InnovationTestProgram;
