import React from "react";
import CEO from "../images/ceo.jpeg";
import { Container, Row, Col } from "react-bootstrap";

const FounderMessage = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="p-5 align-items-center">
        <Col lg={5}>
          <div className="text-center">
            <img src={CEO} alt="" className="w-75 h-75" style={{ border:'5px solid #ddd', padding :'20px', borderRadius : '50px 10px 50px 10px'}} />
            <h4 className="mt-2"><b>Mr. Ankit Agarwal</b></h4>
            <p>FOUNDER &amp; CEO <i>of</i> AFFINITY</p>
          </div>
        </Col>
        <Col lg={7}>
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "700",
              marginBottom: "30px",
            }}
          >
            FOUNDER &amp; CEO's Message{" "}
          </h1>
          <h5>If four things are followed:</h5>
          <ul>
            <li>Having a great aim</li>
            <li>Acquiring knowledge</li>
            <li>Hard work</li>
            <li>Perseverance</li>
          </ul>
          <h5>Then anything can be achieved. - <b>By A.P.J. Abdul Kalam</b></h5>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px"
            }}
          >
            The above quote by such an inspiring person gave me the inspiration and motivation to seek my aim “what I want to do for the students and how I can achieve to our vision & mission of the institute”.
          </p>

          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px"
            }}
          >
            <b>Affinity classes</b>, with the said philosophy begin its borne voyage with small number of students and a single class room in year 2017-2018, but with the untiring attempts of our staff, constant support of parents and zealous performance of our students this small beginning become big and touched the pinnacle of success.
          </p>

          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px"
            }}
          >
            I have always believed that, “Excellence, result and achieving any aim is not any opportunity gained by luck, it is always what I have said for the quotation said by APJ Abdul kalam – the same four things to be followed by each and every one.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default FounderMessage;
