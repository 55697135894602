import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const AdmissionProcedure = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={12} className="px-5">
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "700",
              marginBottom: "30px",
            }}
          >
            Admission Procedure
          </h1>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >
            Affinity Classes team is always there to help and guide you before
            you decide to take admission in any of the course of Affinity
            Classes. Student or Parent is required to put your query on the
            given query form on the home page. Our counselor will call you to
            clear your doubts and also will give complete knowledge of the
            specified course.
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >
            You can also directly call our counselors on the under mentioned
            numbers :
          </p>
          <div className="text-center">
            <h5>OR</h5>
          </div>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >
            The best is your personal visit both student and parent at our head
            office at Rajgarh Road, Guwahati. Here you get the opportunity to
            get face to face counseling and the most important you meet our best
            faculty members.
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >
            Once you are clear about the course opted , one has to fill the form
            in any one of the ways i.e. offline or online and then proceed for
            the payments.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AdmissionProcedure;
