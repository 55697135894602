const AllTestimonials = [
    {
        id : 1,
        name : 'Student Name',
        content : 'I have been a student at Affinity Classes and from what I have experienced, I can tell you it’s a great institute. I thanks my mentors for providing me the correct path in achieving my goal. I personally thanks to all staff of Affinity and especially to Ankit Sir – my best. Thanks Ankit Sir. I am so lucky and proud to be a part of Affinity Classes.',
        image : "https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
    },
    {
        id : 2,
        name : 'Student Name',
        content : 'My decision to join Affinity Classes was made by the guidance of my friends and seniors. I decided to visit the center at Rajgarh road. Looking to the discipline, presentation  and teaching by the faculty made my decision a final. From my depth of heart I thank everyone of Affinity Classes for making me to crack NEET with such great score. Thanks again.',
        image : "https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
    },
    {
        id : 3,
        name : 'Student Name',
        content : 'It was my immense fortune to be a part of Affinity Classes. The entire faculty and management leaves no stone unturned to shape one’s future. Huge respect, love and devotion for entire faculty members and staff. It is your all efforts that make me to count myself into better profession.',
        image : "https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
    },
    {
        id : 4,
        name : 'Student Name',
        content : 'Affinity Classes has offered me countless opportunities to indulge in and showcase my talent through different social events and cultural competition . It has enabled and nurture my creativity. It will forever remain a cherished memory I have of Affinity Classes. ',
        image : "https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
    },
    {
        id : 5,
        name : 'Student Name',
        content : 'I am proud to be an alumni of Affinity Classes. It has been a fantastic journey of learning for me. The teaching methodology and the test series, it all turned me different and gave confidence in every way. I thank to all mentors of Affinity for my achievement. I wish Affinity Classes for touching new heights. ',
        image : "https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
    },
    {
        id : 6,
        name : 'Student Name',
        content : 'I always remember the scolding of Nilay Sir and his pet saying “Aur Bhai”. Thanks to both Nilay Sir and Ankit Sir for making me and my parents proud. I always like to hear from you all. My regards to you both sir.',
        image : "https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
    },
    {
        id : 7,
        name : 'Student Name',
        content : 'For me Affinity Classes is a synonym to “Success”. So much dedication, discipline, and personal care for each and every student. I am proud to be a student of Affinity Classes. My wishes and regards to all faculty and staff of Affinity Classes.',
        image : "https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
    },
    {
        id : 8,
        name : 'Student Name',
        content : 'It was an opportunity given by Ankit Sir to reappear for the NEET exam. I was not able to continue further due to my financials. It was Ankit Sir who met my parents and gave full support for one more time. Today what I am is all by you sir. Thanks to all members of Affinity Classes.',
        image : "https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
    },
    {
        id : 9,
        name : 'Student Name',
        content : '“The ones, who are crazy enough to think they can change the world, are the ones that do”. Whenever any one asks for my classes at Affinity, I always say the above lines. Thanks to all faculty , staff and management to be part of my success.',
        image : "https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
    },
    {
        id : 10,
        name : 'Student Name',
        content : 'If I look back to the days when my preparations were started, I remember the rigorous continuous, test on every Sunday but that has given me to what I wanted to be. I will always credit Affinity Classes to make serve for the nation with such noble profession.',
        image : "https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
    },
]

export default AllTestimonials