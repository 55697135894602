import React from "react";
import { Container, Row } from "react-bootstrap";

const LocateUs = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-4">
            <div className="card p-5 shadow" style={{ height: '250px', borderTop:'5px solid teal' }}>
              <h4 class="text-center font-weight-bolder">Mail Us</h4>
              <hr/>
              <a href="mailto:info@affinityclasses.in" className="text-center text-decoration-none mt-3 text-dark">affinityclasses.in@gmail.com</a>
            </div>
          </div>

          <div className="col-lg-4 text-center">
            <div className="card shadow p-5" style={{ height: '250px', borderTop:'5px solid teal' }}>
              <h4 class="text-center font-weight-bolder">Contact No</h4>
              <hr/>
              <a href="tel:+918811073137" className="text-center text-decoration-none mt-3 text-dark">
 +91 6002-654-752</a>
              <a href="tel:+91 7002131659" className="text-center text-decoration-none text-dark">+91 6002-064-905</a>
            </div>
          </div>

          <div className="col-lg-4 text-center">
            <div className="card shadow p-5" style={{ height: '250px', borderTop:'5px solid teal' }}>
              <h4 class="text-center font-weight-bolder">Address</h4>
              <hr/>
              <a href="https://goo.gl/maps/1a4BkmhGQyqfgg9y6" className="text-center text-decoration-none mt-3 text-dark">2nd Floor, T.D. Complex, Above Radhika Sweets, Rajghar Road, Near Big Bazaar, Guwahati - 07</a>
            </div>
          </div>
        </div>
      </div>


      {/* Contact form */}
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-12 mb-5">
            <h2 className="display-6 fw-bolder text-center">Contact Us</h2>
            <p className="text-center mb-5">We'd love to hear from you, please drop us a line if you've any query</p>
            <form>
              <div className="row">
                <div className="col-lg-6">
                  <div class="mb-3">
                    <label for="name" class="form-label">Name</label>
                    <input type="email" class="form-control p-3" id="name" name="name" placeholder="Name" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="mb-3">
                    <label for="email" class="form-label">Email address</label>
                    <input type="email" name="email" placeholder="Email" class="form-control p-3" id="email" aria-describedby="emailHelp" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="mb-3">
                    <label for="phone" class="form-label">Phone</label>
                    <input type="text" class="form-control p-3" placeholder="Phone" id="phone" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="mb-3">
                    <label for="subject" class="form-label">Subject</label>
                    <input type="text" class="form-control p-3" placeholder="Subject" id="subject" />
                  </div>
                </div>
                <div class="mb-3">
                  <label for="phone" class="form-label">Message</label>
                  <textarea name="message" id="" class="form-control p-3" rows="3" placeholder="Enter message"></textarea>
                </div>
                <div className="col-lg-12">
                  <div className="text-center">
                    <button type="submit" class="btn btn-outline-primary rounded-pill py-2 px-4">Send Message</button>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>

      {/* Map Location */}
      <div className="container-fluid">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3580.9043840539657!2d91.7672684146448!3d26.167243283454273!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5919f9f5bee1%3A0x99777dd35aa7652!2sAffinity%20Classes!5e0!3m2!1sen!2sin!4v1645621487880!5m2!1sen!2sin" width="100%" height="450" style={{ border: '0' }} allowfullscreen="" loading="lazy" title="Affinity Classes"></iframe>
      </div>
    </Container>
  );
};

export default LocateUs;
