import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const RegistrationForm = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row>
        <Col className="p-5">
          <h2>Registration Form</h2>
        </Col>
      </Row>
    </Container>
  );
};

export default RegistrationForm;
