import React from "react";
import CoursesNav from "../components/CoursesNav";
import CourseImg from "../images/course/crash_course_web.jpg"
import { Container, Row, Col } from "react-bootstrap";

const CrashCourse = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={9} className="px-5 mb-3">
          <Row>
            <Col lg={6}>
              <h1
                style={{
                  fontSize: "30px",
                  fontWeight: "700",
                  marginBottom: "30px",
                }}
              >
                Crash Course
              </h1>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "18px",
                  lineHeight: "30px",
                  textIndent: "30px",
                  marginTop: "20px",
                }}
              >
                A class in which a lot of information is taught in a short period of time.  This is very beneficial for the students who want to revise the complete syllabus for Physics, Mathematics, Chemistry and Biology in a very short span of time. It has been noticed that it is mostly opted by the students who have just attempted their board exam and want to attempt the NEET / JEE exam at the same time. Very effective course for the aspirant to know his / her preparation and performance.
              </p>
            </Col>
            <Col lg={6}>
              <img src={CourseImg} alt="" />
            </Col>
          </Row>
        </Col>
        <CoursesNav />
      </Row>
    </Container>
  );
};

export default CrashCourse;
