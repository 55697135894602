const importantLinks = [
    {
        id : 1,
        link : '/scholarship-text',
        image : require("../images/links/aff-sat.png"),
    },
    {
        id : 2,
        link : '/innovation-assestment-test',
        image : require("../images/links/IAT-01.jpg"),
    },
    {
        id : 3,
        link : '/foundation-course',
        image : require("../images/links/Courses-01.jpg"),
    },
    {
        id : 4,
        link : '/contact',
        image : require("../images/links/Querry form-01.jpg"),
    },
    {
        id : 5,
        link : '/scholarship',
        image : require("../images/links/scholarship-01.jpg"),
    },
    {
        id : 6,
        link : '/fee-structure',
        image : require("../images/links/Fees-01.jpg"),
    },
    {
        id : 7,
        link : '/admission-procedure',
        image : require("../images/links/Admission-01.jpg"),
    },
    {
        id : 8,
        link : '/library',
        image : require("../images/links/Features-01.jpg"),
    },
    {
        id : 9,
        link : '/notable-achievers',
        image : require("../images/links/achievements-01.jpg"),
    },
    {
        id : 10,
        link : '/',
        image : require("../images/links/brochures-01.jpg"),
    },
    {
        id : 11,
        link : '/apply-online',
        image : require("../images/links/Apply Online-01.jpg"),
    },
    {
        id : 12,
        link : '/contact',
        image : require("../images/links/Contact Us-01.jpg"),
    },
]

export default importantLinks