import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Laboratories = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={12} className="px-5">
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "700",
              marginBottom: "30px",
            }}
          >
            Laboratories
          </h1>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >Affinity Classes have  fully equipped laboratories for the students especially who are enrolled in our School Integrated Program and also in the Foundation Course. For the students laboratories provide an experimental foundation for the theoretical concepts introduced in the lectures, students get an opportunity to verify some of the ideas themselves.</p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >Our laboratories have three different sections for the subjects Chemistry, Physics and Biology.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Laboratories;
