import React from "react";
import CoursesNav from "../components/CoursesNav";
import CourseImg from "../images/course.jpeg"
import { Container, Row, Col } from "react-bootstrap";

const FoundationCourse = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={9} className="px-5 mb-3">
          <Row>
            <Col lg={6}>
            <h1
              style={{
                fontSize: "30px",
                fontWeight: "700",
                marginBottom: "30px",
              }}
            >
              Foundation Course
            </h1>
            <p
              style={{
                textAlign: "justify",
                fontSize: "18px",
                lineHeight: "30px",
                textIndent: "30px",
                marginTop: "20px",
              }}
            >
              As the name of the course says FOUNDATION, a student need to start
              his / her foundation from class 8th and it goes up to class Xth. Any
              student whose aim is for NEET / JEE / KVYP / BOARD must go for the
              foundation course. The feature and benefits of the foundation course
              are :
            </p>
            <ul style={{ marginTop: '20px', fontSize: '18px' }}>
              <li>
                Course module covers the entire topics of Maths and Science for
                Class VIIIth –IXth – Xth.
              </li>
              <li>Lays a strong foundation for Medical and Engineering.</li>
              <li>
                Student learns the technique of problem solving strategies & time
                management.
              </li>
              <li>
                Student is well prepared for :
                <ul style={{ marginTop: '10px' }}>
                  <li>School Board Examination ( Any Board )</li>
                  <li>NTSE / KVPY foundation / OLYMPIAD</li>
                  <li>NEET / IIT – JEE</li>
                </ul>
              </li>
            </ul>
            </Col>
            <Col lg={6}>
              <img src={CourseImg} alt="" />
            </Col>
          </Row>
        </Col>
        <CoursesNav />
      </Row>
    </Container>
  );
};

export default FoundationCourse;
