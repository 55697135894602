import React, { useState } from "react";
import { Link } from "react-router-dom";

const FILE_URL = './Academy_final_print.pdf';
const FILE_URL_2 = './FINAL_BROCHURE.pdf';

const Header = (props) => {
  const [sideMenu, setSideMenu] = useState(false);

  const handleDownload = (event) => {
    event.preventDefault();
    const url = event.target.href;
    const filename = url.substring(url.lastIndexOf('/') + 1);
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  return (
    <header
      id="header"
      className="sticky-top d-flex align-items-center shadow"
      style={{ backgroundColor: "#660066" }}
    >
      <div className="container d-flex justify-content-lg-center">
        <nav id="navbar" className={`${sideMenu ? "navbar-mobile" : "navbar"}`}>
          {/* === */}

          <div className="mobile-nav-btn-div">
            <button
              className="mobile-nav-toggle"
              onClick={() => setSideMenu(!sideMenu)}
            >
              <i class="fa fa-align-justify"></i>
            </button>
          </div>

          {/* === */}
          {sideMenu && (
            <button
              className="cross-btn"
              onClick={() => setSideMenu(!sideMenu)}
            >
              <i class="fa fa-times" style={{ fontSize: "1.5rem" }}></i>
            </button>
          )}

          <ul>
            {/**************************** Home ************************/}
            <li>
              <Link to="/" className="nav-link scrollto active">
                HOME
              </Link>
            </li>

            {/**************************** About ************************/}
            <li className="dropdown">
              <Link className="nav-link scrollto" to="#">
                <span>ABOUT</span> <i className="fa fa-angle-down"></i>
              </Link>
              <ul>
                <li>
                  <Link to="/overview">An Overview</Link>
                </li>
                <li>
                  <Link to="/vision">Vision</Link>
                </li>
                <li>
                  <Link to="/mission">Mission</Link>
                </li>
                <li>
                  <Link to="/founder-message">Founder &amp; CEO's Message</Link>
                </li>
                <li>
                  <Link to="/managing-director-message">Managing Director Message</Link>
                </li>
              </ul>
            </li>

            {/**************************** Courses ************************/}
            <li className="dropdown">
              <Link className="nav-link scrollto" to="#">
                <span>COURSES</span> <i className="fa fa-angle-down"></i>
              </Link>
              <ul>
                <li>
                  <Link to="/foundation-course">Foundation Course</Link>
                </li>
                {/* <li>
                  <Link to="/bridge-course">Bridge Course</Link>
                </li> */}
                <li>
                  <Link to="/school-integrated-program">School Integrated Program (SIP)</Link>
                </li>
                <li>
                  <Link to="/crash-course">Crash Course</Link>
                </li>
                <li>
                  <Link to="/dropper-course">Dropper Course</Link>
                </li>
                <li>
                  <Link to="/summer-course">Summer Course</Link>
                </li>
                {/* <li>
                  <Link to="/distance-learning-program">Distance Learning Course</Link>
                </li> */}
              </ul>
            </li>

            {/**************************** Admission ************************/}
            <li className="dropdown">
              <Link className="nav-link scrollto" to="/">
                <span>ADMISSION</span> <i className="fa fa-angle-down"></i>
              </Link>
              <ul>
                <li>
                  <Link to="/admission-procedure">Admission Procedure</Link>
                </li>
                <li>
                  <a href={FILE_URL} to={FILE_URL} onClick={handleDownload}>Affinity Academy Brochure</a>
                </li>
                <li>
                  <a href={FILE_URL_2} to={FILE_URL_2} onClick={handleDownload}>Affinity Classes Brochure</a>
                </li>
                <li>
                  <Link to="/apply-online">Apply Online</Link>
                </li>
                <li>
                  <Link to="/scholarship">Scholarship</Link>
                </li>
                <li>
                  <Link to="/fee-structure">Fee Structure</Link>
                </li>
                <li>
                  <Link to="/payment-procedure">Payment Procedure</Link>
                </li>
              </ul>
            </li>

            {/**************************** Test ************************/}
            <li className="dropdown">
              <Link className="nav-link scrollto" to="#">
                <span>TEST</span> <i className="fa fa-angle-down"></i>
              </Link>
              <ul>
                <li>
                  <Link to="/scholarship-text">Scholarship Test (AFF-SAT)</Link>
                </li>
                <li>
                  <Link to="/innovation-assestment-test">Innovative Assestment Test (IAT)</Link>
                </li>
                <li>
                  <Link to="/registration">Registration Form</Link>
                </li>
                <li>
                  <Link to="/payment-method">Payment Method</Link>
                </li>
                <li>
                  <Link to="/test-results">Test Results</Link>
                </li>
              </ul>
            </li>

            {/**************************** Feature ************************/}
            <li className="dropdown">
              <Link className="nav-link scrollto" to="#">
                <span>FEATURES</span> <i className="fa fa-angle-down"></i>
              </Link>
              <ul>
                <li>
                  <Link to="/library">Library</Link>
                </li>
                <li>
                  <Link to="/hostels">Hostels</Link>
                </li>
                <li>
                  <Link to="/laboratories">Laboratories</Link>
                </li>
                <li>
                  <Link to="/seminars">Seminars</Link>
                </li>
                <li>
                  <Link to="/sports">Sports</Link>
                </li>
                <li>
                  <Link to="/cultural-events">Cultural Events</Link>
                </li>
                <li>
                  <Link to="/cafeteria">Cafeteria</Link>
                </li>
              </ul>
            </li>

            {/**************************** Achievements ************************/}
            <li className="dropdown">
              <Link className="nav-link scrollto" to="#">
                <span>ACHIEVEMENTS</span> <i className="fa fa-angle-down"></i>
              </Link>
              <ul>
                <li>
                  <Link to="/notable-achievers">Notable Achievers</Link>
                </li>
                <li>
                  <Link to="/achievers">Achievers</Link>
                </li>
                <li>
                  <Link to="/testimonials">Testimonials</Link>
                </li>
              </ul>
            </li>

            {/**************************** Gallery ************************/}
            <li className="dropdown">
              <Link className="nav-link scrollto" to="#">
                <span>GALLERY</span> <i className="fa fa-angle-down"></i>
              </Link>
              <ul>
                <li>
                  <Link to="/centers">Our Centers</Link>
                </li>
                <li>
                  <Link to="/infrastructure-gallery">Infrastructure</Link>
                </li>
                <li>
                  <Link to="/">Cultural Events</Link>
                </li>
                <li>
                  <Link to="/">Seminars</Link>
                </li>
                <li>
                  <Link to="/">Media</Link>
                </li>
                <li>
                  <Link to="/youtube-links">YouTube Links</Link>
                </li>
              </ul>
            </li>

            {/**************************** Contact ************************/}
            <li className="dropdown">
              <Link className="nav-link scrollto" to="#">
                <span>CONTACT</span> <i className="fa fa-angle-down"></i>
              </Link>
              <ul>
                <li>
                  <Link to="/admission-cell">Admission Cell</Link>
                </li>
                <li>
                  <Link to="/career">Career Cell</Link>
                </li>
                <li>
                  <Link to="/contact">Locate Us</Link>
                </li>
              </ul>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
      </div>
    </header>
  );
};

export default Header;
