import React from 'react'
import MissionImg from '../images/mission.png'
import {Container, Row, Col} from 'react-bootstrap';

const Mission = () => {
  return (
    <Container fluid style={{padding: '0'}}>
      <Row className="p-5 align-items-center">
        <Col lg={6}>
          <h1 style={{ fontSize : '30px', fontWeight:'700', marginBottom:'30px'}}>OUR MISSION</h1>
          <p style={{ textAlign : 'justify', fontSize : '18px', lineHeight:'30px'  }}>When we started our journey as an educator in North-East, it was our dream and mission to leave no stone unturned in educating as many students as we can, without any cultural or financial discrimination. It is our aim to help students realize their potential and such we have been excelling from the very first year of our existence. Achieving the mission of providing education to all students on an affordable price and creating toppers in the field of medical and engineering is all with the efforts of our students, co-faculties, management and workers. Hence, we as a team, look forward to many more such milestones in the days to come.</p>
        </Col>
        <Col lg={6}>
          <img src={MissionImg} alt="" className="w-100 h-100" />
        </Col>
      </Row>
    </Container>
  )
}

export default Mission