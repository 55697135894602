import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import SliderOne from "../images/facility/3.jpg";
import SliderTwo from "../images/facility/2.jpg";

const Library = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={8} className="px-5">
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "700",
              marginBottom: "30px",
            }}
          >
            Library
          </h1>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >The concept of providing 24X7 library facility is that the student can study in group while they are in different boys and girls hostel. Group study and the presence of the tutor enhances the knowledge, boost self confidence and maximum utilization of time.</p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >Affinity libraries are well equipped with the relevant course material, books, previous year question papers, solutions of the test series and the latest magazines and journals.</p>
        </Col>
        <div className="col-lg-4">
          <Carousel className="slider">
            <Carousel.Item>
              <img className="d-block w-100 banner-image" style={{ padding: '0px' }} src={SliderOne} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100 banner-image" style={{ padding: '0px' }} src={SliderTwo} alt="Second slide" />
            </Carousel.Item>
          </Carousel>
        </div>
      </Row>
    </Container >
  );
};

export default Library;
