import React from "react";
import { Container, Row } from "react-bootstrap";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";

const gallery = [
  {
    id: 1,
    image: require("../images/course.jpeg"),
  },
  {
    id: 2,
    image: require("../images/course.jpeg"),
  },
];

const InfrastructureGallery = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Container>
        <Row className="mt-5 mb-5 p-2 align-items-center">
          <div className="text-center">
            <h3 class="mb-5 text-uppercase">
              <b>Infrastructure Gallery</b>
            </h3>
          </div>
          <LightGallery plugins={[lgZoom, lgVideo]} mode="lg-fade">
            {gallery.map((gallery) => (
              <span
                className="gallery-item"
                data-src={gallery.image}
                data-sub-html="<h4>Photo by - AFFINITY</h4>"
              >
                <img
                  className="img-responsive gallery-image p-1"
                  src={gallery.image}
                  alt=""
                  style={{ marginBottom: "30px", cursor: "pointer", height: "100%", width: '270px' }}
                />
              </span>
            ))}
          </LightGallery>
        </Row>
      </Container>
    </Container>
  );
};

export default InfrastructureGallery;
