import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const PaymentProcedure = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row className="mt-5 mb-5 p-2">
        <Col lg={12} className="px-5">
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "700",
              marginBottom: "30px",
            }}
          >
            Admission Procedure
          </h1>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >
            Student who has apply online or offline can make the payment for the amount as guided by the admission authorized person. The admission cell will provide you the complete details of your fee for the course and also any scholarship if applicable.
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "30px",
              textIndent: "30px",
              marginTop: "20px",
            }}
          >
            Student can make payment through the following modes:
          </p>
          <ul style={{ marginLeft : '50px'}}>
            <li>Cash Deposit in account department at the Head Office.</li>
            <li>Swapping of your Debit Card / Credit Card at Head Office.</li>
            <li>Demand Draft in favor of Affinity Classes payable at Guwahati.</li>
            <li>Cheque  in favor of Affinity Classes which can be deposited in any branch of ….. Bank .</li>
            <li>Net banking transfer to the under mentioned bank details. ………….</li>
            <li>Phone pay / G pay / Paytm on the given QR code. </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentProcedure;
