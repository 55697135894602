import React from "react";
import Logo2 from "../images/logo/logo5.png";
import Logo4 from "../images/logo/logo4.png";
import './Topbar.css'

const Topbar = () => {
  return (
    <div className="row bg-warning">
      <div className="col-lg-12 bg-light">
          <div className="col-12 p-1" style={{ background:'#4A2683' }}>
            <div className="text-center">
              <h1 style={{ fontSize : '70px'}} className="top-bar-header text-light">AFFINITY GROUP OF INSTITUTIONS</h1>
              <div className="row">
                <div className="col-lg-2">
                  <img src={Logo2} alt="" />
                </div>
                <div className="col-lg-8">
                  <h2 class="text-light" style={{ fontSize : '40px'}}>No. 1 Institute in Academic Excellence</h2>
                  <h3 class="text-light">( JEE/NEET/BOARD/SIP )</h3>
                </div>
                <div className="col-lg-2">
                  <img src={Logo4} alt="" />
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
