import React from 'react'
import About from '../images/about.png'
import {Container, Row, Col} from 'react-bootstrap';

const Vision = () => {
  return (
    <Container fluid style={{padding: '0'}}>
      <Row className="p-5 align-items-center">
        <Col lg={6}>
          <h1 style={{ fontSize : '30px', fontWeight:'700', marginBottom:'30px'}}>OUR VISION</h1>
          <p style={{ textAlign : 'justify', fontSize : '18px', lineHeight:'30px'  }}>Affinity Classes was founded with a vision to help students achieve their goals with proper guidance and care. The institute have always sought the all-round development of our student while assisting them fulfil their dreams. If we look back on our experiences of this journey, we have come a long way. From starting with a single classroom comprising of a single batch which now ranges from a number of batches of hardworking and successful students by providing regular classes, regular test series, 24x7 library facility, hostel facility etc. Affinity classes have proved to set a benchmark in terms of quality education to all and especially to deprived students of North-East.</p>
        </Col>
        <Col lg={6}>
          <img src={About} alt="" className="w-100 h-100" />
        </Col>
      </Row>
    </Container>
  )
}

export default Vision