import Carousel from "react-bootstrap/Carousel";
// import SliderOne from "../images/slider1.jpg";
import SliderTwo from "../images/slider2.jpg";
import SliderThree from "../images/slider3.jpg";
// import SliderFour from "../images/slider4.jpg";
import SliderFive from "../images/slider5.jpg";
const Slider = () => {
  return (
    <Carousel className="slider">
      <Carousel.Item>
        <img className="d-block w-100 banner-image" style={{padding:'0px'}} src={SliderThree} alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100 banner-image" style={{padding:'0px'}} src={SliderTwo} alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100 banner-image" style={{padding:'0px'}} src={SliderFive} alt="Second slide" />
      </Carousel.Item>
    </Carousel>
  );
};

export default Slider;
